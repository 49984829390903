import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description

  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        meta={[{ name: "description", content: siteDescription }]}
        title={siteTitle}
      />
      <SEO title="about me" />
      <h2>About Me</h2>
      <p>
        你好，我的网名叫 {data.site.siteMetadata.author}，是一名 Web 前端开发工程师。
        可以在我的
        <span
          style={{
            padding: '0 4px'
          }}
        >
          <a href="https//www.github.com/shanejix">Github</a>
        </span>
        上找到我做的开源项目。
        偶尔写写
        <span
          style={{
            padding: '0 4px'
          }}
        >
          <a href="https//www.shanejix.com">博客</a>
        </span>,
        用于整理和思考，博文仅用于展示思考和整理的结果，方便查阅和复盘。多数文章为自己而写，但也希望能为他人提供价值，
        所见所学会记录到
        <span
          style={{
            padding: '0 4px'
          }}
        >
          <a href="https://wiki.shanejix.vercel.app/">Wiki</a>
        </span>。
      </p>

      <p>
        你可以免费阅读我的博客，也可以在
        <span
          style={{
            padding: '0 4px'
          }}
        >
          <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/">注明出处和作者的前提下</a>
        </span>

        随意转载我的博客。感谢你的阅读，赞赏不是最好的鼓励方式，转发、评论或直接
        <span
          style={{
            padding: '0 4px'
          }}
        >
          <a href="mailto:shanejix@hotmail.com">邮件</a>
        </span>

        与我，才是对我最大的慰藉，让我觉得真正影响到了一些人。
      </p>

      {/* <p>
      “从当下出发”是我给自己总结的“五字箴言”，鞭策自己不拖沓，多做多思考，不骄不躁，要脚踏实地做事，坚持自己的梦想和本心。
    </p> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
